import algosdk, { Transaction } from 'algosdk'
import { connector, peraWallet, walletStore } from '@/stores/wallet-store'
import MyAlgoConnect from '@randlabs/myalgo-connect'
import { algoClient } from '@/algo-client'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'
import { checkDeviceType } from '@/helper/ua-parser'
import { apiService } from '@/services/api-services'
import { ethers } from 'ethers'
import { intersection } from 'lodash'
import { encode, decode } from 'uint8-to-base64'

export interface State {
  key?: string
  value?: {
    byte?: string
    type?: number
    uint: number
  }
  name?: string
}

const getNftIDKey = (localIndex: number) => {
  const paddedId = ethers.utils.zeroPad(ethers.utils.hexlify(localIndex), 8)
  return paddedId
}

const signTransactions = async (walletType: string, txns: Transaction | Transaction[]): Promise<any> => {
  const tnxParameters = Array.isArray(txns) ? txns : [txns]
  if (walletType === 'my-algo-wallet') {
    const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false })
    const groupID = algosdk.computeGroupID(tnxParameters)
    tnxParameters.forEach((item) => (item.group = groupID))
    const signedTxns = await myAlgoConnect.signTransaction(tnxParameters.map((txn) => txn.toByte()))
    console.log("=====blob", signedTxns.map((item) => item.blob))
    return signedTxns.map((item) => item.blob)
  } else {
    console.log("========HERE", peraWallet)
    const groupID = algosdk.computeGroupID(tnxParameters)
    tnxParameters.forEach((item) => (item.group = groupID))
    // const txnsToSign = tnxParameters.map((txn) => {
    //   const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64')
    //   return {
    //     txn: encodedTxn,
    //   }
    // })
    const multipleTxnGroups = tnxParameters.map((txn) => {
      return { txn: txn, signers: [walletStore.account] }
    })
    const signedTxns = await peraWallet.signTransaction([multipleTxnGroups as any])
    const decodedResult = signedTxns.map((element: any) => {
      return element ? new Uint8Array(Buffer.from(element, 'base64')) : null
    })
    const result = decodedResult
    console.log("=====result", result)
    return result
    // const requestParams = [txnsToSign]
    // const request = formatJsonRpcRequest('algo_signTxn', requestParams)
    // //Request open Pera Wallet application on mobile browser
    // const deviceType = checkDeviceType()
    // if (deviceType?.device?.type === 'mobile') {
    //   const deepLink = deviceType?.os?.name === 'iOS' ? 'algorand-wc://' : 'algorand://'
    //   window.location.href = deepLink
    // }
    // const result: Array<string | null> = await connector.sendCustomRequest(request)
    // const decodedResult = result.map((element) => {
    //   return element ? new Uint8Array(Buffer.from(element, 'base64')) : null
    // })
    // const signedTxns = decodedResult
    // return signedTxns
  }
}

async function readLocalState(client, account, index): Promise<State[]> {
  const localState: State[] = []
  const accountInfoResponse = await client.accountInformation(account).do()
  if (!accountInfoResponse['apps-local-state']) return []
  for (let i = 0; i < accountInfoResponse['apps-local-state'].length; i++) {
    if (
      accountInfoResponse['apps-local-state'][i].id == index &&
      accountInfoResponse['apps-local-state'][i][`key-value`]
    ) {
      for (let n = 0; n < accountInfoResponse['apps-local-state'][i][`key-value`].length; n++) {
        localState.push(accountInfoResponse['apps-local-state'][i][`key-value`][n])
      }
    }
  }

  return localState
}

async function readGlobalState(client, index): Promise<State[]> {
  const applicationInfoResponse = await client.getApplicationByID(index).do()
  let globalState = []
  if (applicationInfoResponse['params']['global-state']) {
    globalState = applicationInfoResponse['params']['global-state']
  }
  return globalState
}

async function checkOptInStatus(walletAddress: string, appID: number): Promise<boolean> {
  const accountInformation = await algoClient.accountInformation(walletAddress).do()
  const appLocalState = accountInformation['apps-local-state']?.find((item) => item.id === appID)
  return !!appLocalState
}

async function checkOptInASAStatus(walletAddress: string, asaID: number) {
  const accountInfo = await algoClient.accountInformation(walletAddress).do()
  const isOptIned = accountInfo['assets'].find((item) => item['asset-id'] === asaID)
  return !!isOptIned
}

function buildState(states: State[], stateType) {
  if (!states || states.length === 0) return {}
  const targetStates = {} as any
  Object.keys(stateType).map((key) => {
    const keyBuffer = Buffer.from(stateType[key])
    const keyBase64 = keyBuffer.toString('base64')
    const globalStateObject = states.find((item: any) => item.key === keyBase64)
    targetStates[key] = { ...globalStateObject, name: key }
  })
  return targetStates
}

const getPopoNftOwned = async (walletAddress: string) => {
  const accountInfo = await algoClient.accountInformation(walletAddress).do()
  const assets = accountInfo.assets.filter((asset) => asset.amount === 1 && asset['asset-id'])
  const assetIds = assets.map((asset) => asset['asset-id'])
  if (assetIds.length) {
    const popoNfts = await apiService.popoNfts.find({ assetId_in: assetIds })
    return popoNfts
  } else return []
}

const getWearableNftOwned = async (walletAddress: string) => {
  const accountInfo = await algoClient.accountInformation(walletAddress).do()
  const assets = accountInfo.assets.filter((asset) => asset.amount === 1 && asset['asset-id'])
  const assetIds = assets.map((asset) => asset['asset-id'])
  if (assetIds.length) {
    const wearableNfts = await apiService.wearableNfts.find({ assetId_in: assetIds })
    return wearableNfts
  } else return []
}

const getAssetByID = async (assetID: number) => {
  return await algoClient.getAssetByID(assetID).do()
}

const mysteryBoxNFTs = async () => {
  const mintAppAccount = process.env.VUE_APP_MINT_APP_ACCOUNT || ''
  const accountInfo = await algoClient.accountInformation(mintAppAccount).do()
  const mysteryBoxNFTs = accountInfo['created-assets'].map((asset) => asset['index'])
  return mysteryBoxNFTs
}

const getMysteryBoxesOwned = async (walletAddress: string) => {
  const allMysteryBoxNFTs = await mysteryBoxNFTs()
  const accountInfo = await algoClient.accountInformation(walletAddress).do()
  const assets = accountInfo.assets.filter((asset) => asset.amount === 1 && asset['asset-id'])
  const assetIds = assets.map((asset) => asset['asset-id'])
  const walletMysteryBoxes = intersection(allMysteryBoxNFTs, assetIds)
  return walletMysteryBoxes
}

export {
  getNftIDKey,
  signTransactions,
  readGlobalState,
  readLocalState,
  checkOptInASAStatus,
  buildState,
  checkOptInStatus,
  getPopoNftOwned,
  getWearableNftOwned,
  getAssetByID,
  mysteryBoxNFTs,
  getMysteryBoxesOwned
}
