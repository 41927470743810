import { algoClient } from '@/algo-client'
import { walletStore } from '@/stores/wallet-store'
import algosdk from 'algosdk'
import { buildState, readGlobalState, readLocalState, signTransactions, getNftIDKey, checkOptInStatus } from './utils'
import { get } from 'lodash'
import _ from 'lodash'

const NFT_MINTER_CONFIG = {
  approvalName: 'nft-minter-approval.py',
  clearName: 'nft-minter-clear.py',
  globals: {
    SYSTEM_APP_ID: 'sai',
    ASA_ID: 'ai',
    NFT_ID_PREFIX: 'nip',
    NFT_NAME_PREFIX: 'nnp',
    NFT_URL: 'nu',
    NFT_FEE: 'fee',
    NFT_MINTED: 'nm',
    NFT_TOTAL: 'nt',
    NFT_OWNER: 'owner',
    NFT_RECEIVER: 'rec',
  },
  locals: {
    MEMBER_TYPE: 'mt',
    NFT_PENDING: 'np',
    NFT_MINTED: 'nm',
    NFT_PENDING_ID: getNftIDKey(0),
    NFT_FREE: 'nf',
  },
  callApps: {
    ConfigAsa: 'config_asa',
    ChangeOwner: 'change_owner',
    ChangeReceiver: 'change_receiver',
    SetSystemApp: 'set_system_app',
    Mint: 'mint',
    Claim: 'claim',
    ClaimNftFree: 'claim_nft_free',
  },
  creationFlags: {
    localInts: 10,
    localBytes: 6,
    globalInts: 20,
    globalBytes: 10,
  },
}

export const MINT_MEMBER_TYPE = {
  public: 0,
  whitelist: 1,
}
export class NFTMinterHandler {
  mintAppID = 0
  mintAppAccount? = ''
  globalState?: any
  feeTokenId = 0
  feeReceiver? = ''
  mintFee?: number
  maxMintWhitelistWallet = 1
  maxMintPublicWallet = 1
  totalNFTSupply?: number = 0
  totalNFTMinted?: number = 0

  constructor() {
    this.init()
  }

  async init() {
    const { VUE_APP_MINT_APP_ID, VUE_APP_MINT_APP_ACCOUNT, VUE_APP_FEE_RECEIVER } = process.env
    this.mintAppID = Number(VUE_APP_MINT_APP_ID) || 0
    this.mintAppAccount = VUE_APP_MINT_APP_ACCOUNT
    this.feeReceiver = VUE_APP_FEE_RECEIVER
    this.globalState = await this.getGlobalState()
    this.feeTokenId = get(this.globalState?.ASA_ID, 'value.uint')
    this.mintFee = get(this.globalState.NFT_FEE, 'value.uint')
    this.totalNFTSupply = get(this.globalState.NFT_TOTAL, 'value.uint')
    this.totalNFTMinted = get(this.globalState.NFT_MINTED, 'value.uint')
  }

  async getGlobalState() {
    this.globalState = await readGlobalState(algoClient, this.mintAppID)
    return buildState(this.globalState, NFT_MINTER_CONFIG.globals)
  }

  async getLocalState(account) {
    const res = await readLocalState(algoClient, account, this.mintAppID)
    return buildState(res, NFT_MINTER_CONFIG.locals)
  }

  async sendOptInMintApplication(walletAddress: string) {
    const params = await algoClient.getTransactionParams().do()
    if (params && this.mintAppID) {
      const txn = algosdk.makeApplicationOptInTxn(walletAddress, params, this.mintAppID)
      const signedTxn = await signTransactions(walletStore.selectedWallet, txn)
      const { txId } = await algoClient.sendRawTransaction(signedTxn).do()
      await algosdk.waitForConfirmation(algoClient, txId, 3)
    }
  }

  async mintNFT(walletAddress: string, amount = 1, mintFee = 'ALGO') {
    const params = await algoClient.getTransactionParams().do()
    if (params && this.mintFee && this.mintAppID && this.mintAppAccount && this.feeReceiver) {
      const tranferAlgoTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        to: this.mintAppAccount,
        amount: 100000 * amount, //0.1 ALGO * amount
      })

      const mintTxns = _.map(new Array(amount), (x, index) =>
        algosdk.makeApplicationNoOpTxnFromObject({
          suggestedParams: { ...params, fee: 2 * 1000, flatFee: true },
          from: walletAddress,
          appIndex: this.mintAppID,
          appArgs: [new Uint8Array(Buffer.from(NFT_MINTER_CONFIG.callApps.Mint))],
          note: new Uint8Array(Buffer.from(`#${index}`))
        })
      )

      const tranferAssetsTxn =
        mintFee === 'ALGO'
          ? algosdk.makePaymentTxnWithSuggestedParamsFromObject({
              suggestedParams: { ...params },
              from: walletAddress,
              to: this.feeReceiver,
              amount: this.mintFee * amount,
            })
          : algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
              suggestedParams: { ...params },
              from: walletAddress,
              to: this.feeReceiver,
              amount: this.mintFee * amount,
              assetIndex: this.feeTokenId,
            })

      const signedTxns = await signTransactions(walletStore.selectedWallet, [
        tranferAlgoTxn,
        ...mintTxns,
        tranferAssetsTxn,
      ])
      const { txId } = await algoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(algoClient, txId, 3)
    }
  }

  async getPendingNFT(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    const assetID = get(localState.NFT_PENDING_ID, 'value.uint')
    if (assetID) return await algoClient.getAssetByID(assetID).do()
  }

  async getFreeNFT(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    return get(localState.NFT_FREE, 'value.uint')
  }

  async getMemberType(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    console.log('=========localState', localState)
    return get(localState.MEMBER_TYPE, 'value.uint')
  }

  async getNFTMintedAmount(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    return get(localState.NFT_MINTED, 'value.uint')
  }

  async claimNFT(walletAddress: string, nftAssetID: number) {
    const params = await algoClient.getTransactionParams().do()
    const localIndex = await this.getNFTLocalIndex(walletAddress, nftAssetID)
    if (params && this.mintAppID) {
      const optInASATxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        to: walletAddress,
        amount: 0,
        assetIndex: nftAssetID,
      })

      const claimTxn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 2000, flatFee: true },
        from: walletAddress,
        appIndex: this.mintAppID,
        appArgs: [new Uint8Array(Buffer.from(NFT_MINTER_CONFIG.callApps.Claim)), algosdk.encodeUint64(localIndex)],
        foreignAssets: [nftAssetID],
      })

      const signedTxns = await signTransactions(walletStore.selectedWallet, [optInASATxn, claimTxn])
      const { txId } = await algoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(algoClient, txId, 3)
    }
  }

  async claimNftFree(walletAddress: string, nftAssetID: number) {
    const params = await algoClient.getTransactionParams().do()
    if (params && this.mintAppID && this.mintAppAccount) {
      const optInASATxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        to: walletAddress,
        amount: 0,
        assetIndex: nftAssetID,
      })

      const claimTxn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 2000, flatFee: true },
        from: walletAddress,
        appIndex: this.mintAppID,
        appArgs: [new Uint8Array(Buffer.from(NFT_MINTER_CONFIG.callApps.ClaimNftFree))],
        foreignAssets: [nftAssetID],
        accounts: [this.mintAppAccount],
      })

      const signedTxns = await signTransactions(walletStore.selectedWallet, [optInASATxn, claimTxn])
      const { txId } = await algoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(algoClient, txId, 3)
    }
  }

  async isWhitelistMember(walletAddress: string) {
    const memberType = await this.getMemberType(walletAddress)
    return memberType === MINT_MEMBER_TYPE.whitelist
  }

  async getNFTPendingAssetIds(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    const numberPendingNFT = get(localState.NFT_PENDING, 'value.uint')
    const localStateRaw = await readLocalState(algoClient, walletAddress, this.mintAppID)
    const pendingNFTIds: any[] = []
    for (let i = 0; i < numberPendingNFT; i++) {
      const state = buildState(localStateRaw, { NFT_PENDING_ID: getNftIDKey(i) })
      const id = get(state.NFT_PENDING_ID, 'value.uint')
      pendingNFTIds.push(id)
    }
    return pendingNFTIds
  }

  async isOptedInApplication(walletAddress) {
    const isOptedIn = checkOptInStatus(walletAddress, this.mintAppID)
    return isOptedIn
  }

  async getUserState(walletAddress) {
    const localState = await this.getLocalState(walletAddress)
    const totalNftMinted = get(localState.NFT_MINTED, 'value.uint')
    return { totalNftMinted: totalNftMinted }
  }

  async getGlobalStateData() {
    const globalState = await this.getGlobalState()
    const totalNFTMinted = get(globalState.NFT_MINTED, 'value.uint')
    return { totalNFTMinted: totalNFTMinted }
  }

  async getNFTLocalIndex(walletAddress, nftId): Promise<number> {
    let localIndex = 0
    const localState = await readLocalState(algoClient, walletAddress, this.mintAppID)
    localState.forEach((state: any) => {
      const buf = Buffer.from(state.key, 'base64')
      if (buf.length === 8) {
        const valueNFTId = get(state, 'value.uint')
        if(valueNFTId === nftId) localIndex = Number(algosdk.bytesToBigInt(buf))
      }
    })
    return localIndex
  } 
}
